import React, { createContext, useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import { ShowToast, Severty } from "../helper/toast";
import useRequest from "../hooks/useRequest";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import { useAuthContext } from "./AuthContext";
export const AppStateContext = createContext();

export const AppContextProvider = ({ children }) => {
  const { request } = useRequest();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [bannerList, setBannnerList] = useState([]);
  const [prices, setPrices] = useState([]);
  const [vault, setVaulat] = useState([]);
  const [goldPrice, setGoldPrice] = useState(0);
  const [goldRates, setGoldRates] = useState();
  const [silverPrice, setSilverPrice] = useState(0);
  const [silverRates, setSilverRates] = useState();
  const [serviceFee, setServiceFee] = useState();
  const [refreshBalance, setRefreshBalance] = useState(false);
  const [metal, setMetal] = useState([]);
  const [language, setLanguage] = useState("en");
  const [theme, setTheme] = useState(true);

  const { isLoggedIn } = useAuthContext();

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    let lang = localStorage.getItem("languageSet");
    lang = lang ? lang : "en";

    let theme = localStorage.setItem("theme","true");
    theme = theme ? theme : "true";
    setTheme(theme);
    // setTimeout(() => setLoading(false), 200);
    setLanguage(lang);
  }, []);

  useEffect(() => {
    if (prices.length) return;
    request({
      url: "/common/product-listing",
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log("Success Data, common api:", data);

        if (status) {
          setPrices(data.data);
          setMetal(data.data);
        }
      },
      onError: (error) => {},
    });

    request({
      url: "/admin/service-fees/fees",
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log("Success Data:", data);

        if (data.data.length) {
          setServiceFee(data.data[0]);
        }
      },
      onError: (error) => {},
    });
  }, []);

  useEffect(() => {
    if (!isLoggedIn) return;
    request({
      url: "/app/vault/getVaultList",
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log("Success Data:", data);
        if (status) {
          setVaulat(data.data);
        }
      },
      onError: (error) => {},
    });
    // Additional logic on login
  }, [isLoggedIn]);

  const getGold = (status) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://myntinc.nfusioncatalog.com/service/price/all?currency=USD&withretailtiers=true&withwholesaletiers=true&withCost=true&token=d64cef36-cd2b-4523-827d-326a8098953d&shippingInAsk=true",
    };
    console.log(config, "config----------->");
    axios
      .request(config)
      .then((response) => {
        console.log(response, "response----------------->");
        console.log(JSON.stringify(response?.data[3].Bid));
        let livePrice;
        response = response.data;
        console.log(response);
        response.map((item) => {
          let res = item.SKU === "NFS-16";
          if (res) {
            livePrice = item.BaseAsk;
          }
        });
        let rateOfGold = livePrice;

        setGoldPrice(rateOfGold);
        setGoldRates(rateOfGold);
      })
      .catch((error) => {
        console.log(error);
        const rates = {
          XAU: 2315.23,
          USDXAU: 0.00043022,
        
        };

        setGoldPrice(rates.XAU);
        setGoldRates(rates);
      });
  };

  const getSilver = (status) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://myntinc.nfusioncatalog.com/service/price/all?currency=USD&withretailtiers=true&withwholesaletiers=true&withCost=true&token=d64cef36-cd2b-4523-827d-326a8098953d&shippingInAsk=true",
    };
    console.log(config, "config----------->");

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response?.data[1].Bid), "Price S");
        let livePrice;
        response = response.data;
        console.log(response);
        response.map((item) => {
          let res = item.SKU === "NFS-5";
          if (res) {
            livePrice = item.BaseAsk;
          }
        });

        let rateOfSilver = livePrice;
        console.log(rateOfSilver  ,"live--------")
        setSilverPrice(rateOfSilver);
        setSilverRates(rateOfSilver);
      })
      .catch((error) => {
        console.log(error);
        const rates = {
          USDXAG: 0.036756599 ,
          XAG: 27.1,
        
        };
        setSilverPrice(rates.XAG);
        setSilverRates(rates);
      });
  };

  useEffect(() => {
    getGold();
    getSilver();
  }, []);

  return (
    <AppStateContext.Provider
      value={{
        bannerList,
        password,
        email,
        setEmail,
        setPassword,
        prices,
        goldPrice,
        goldRates,
        silverPrice,
        silverRates,
        serviceFee,
        refreshBalance,
        setRefreshBalance,
        isMobile,
        metal,
        language,
        setLanguage,
        setTheme,
        theme,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppStateContext);
};
